export async function uploadFileInChunks(file, uploadUrl, chunkSize = 50 * 1024 * 1024) {
  console.log("file",file)
  const totalChunks = Math.ceil(file.size / chunkSize);
  const fileId = `${Date.now()}-${file.name}`; // Unique file ID

  for (let chunkIndex = 0; chunkIndex < totalChunks; chunkIndex++) {
    const start = chunkIndex * chunkSize;
    const end = Math.min(start + chunkSize, file.size);
    const chunk = file.slice(start, end);

    const formData = new FormData();
    formData.append('file', chunk);
    formData.append('fileId', fileId);
    formData.append('chunkIndex', chunkIndex);
    formData.append('totalChunks', totalChunks);
    formData.append('path', 'heritage/content');
    formData.append('mimetype', file.type);

    console.log(`Uploading chunk ${chunkIndex + 1} of ${totalChunks}`);

    try {
      const response = await fetch(uploadUrl, {
        method: 'POST',
        body: formData,
      });

      if (!response.ok) {
        throw new Error(`Chunk ${chunkIndex + 1} upload failed with status ${response.status}`);
      }

      const result = await response.json();

      // Check if this is the last chunk and return the URL
      if (chunkIndex + 1 === totalChunks && result.fileUrl) {
        return result.fileUrl; // Return the S3 URL
      }
    } catch (error) {
      console.error(`Error uploading chunk ${chunkIndex + 1}:`, error);
      return; // Stop further uploads if a chunk fails
    }
  }
}
