
import L from "leaflet";
import heritageService from "../../services/heritage.service";
import MarkerClusterGroup from "react-leaflet-cluster";
import PlayContents from "./PlayContents/PlayContents.component";
import MapMenu from "../Map/MapMenu/MapMenu.component";
import WindowMarker from "./WindowMaker/WindowMarker.component";
import LazyLoad from "react-lazyload";
import useDynamicIcon from "../../Hooks/useDynamicIcon";
import { MapContainer, TileLayer, Marker, Popup, useMapEvents ,Polyline } from "react-leaflet";
import { useEffect, useRef, useState, useMemo} from "react";
import { cityOcityService } from "../../services/city_ocity.service";
import { List } from "react-virtualized";
import { useGetActiveAndInactiveIcons } from "./Icons";
import { Footer } from "../footer.component";
import { useNavigate, useParams } from "react-router-dom";
import { filterHeritages } from "./Utils/FilterHeritages";
import { isCity, isHeritage } from './Utils/Utils'
import { CustomEarthControl } from "./EarthControl/EarthControl";
import "../../styles/App.css";
import "../../styles/Map.css";
import markerCityLocation from "../../assets/mapIcons/ocity_marker.png";
import markerCityLocationSelected from "../../assets/mapIcons/ocity_marker_active.png";
import userService from "../../services/user.service";
// #FLAG_123: TODO: HABILITA LAS SIGUIENTES FEATURES
// import roadService from "../../services/road.service";

export function Map() {
  const [zoom, setZoom] = useState(3);
  const [showHeritages, setShowHeritages] = useState(false);
  const [cities, setCities] = useState([]);
  const [heritages, setHeritages] = useState([]);
  const [categoriesSelected, setCategoriesSelected] = useState([]);
  const [filters, setFilters] = useState({});
  const [isAdvanceSearch,SetIsAdvanceSearch] =  useState(false);
  const windowMarkerRef = useRef(null);
  const playContentsRef = useRef(null);
  const mapRef = useRef(null);
  const { cityId ,heritageId ,routeId } = useParams();

  // icon creations
  const iconCity = useDynamicIcon(markerCityLocation, zoom)
  const iconCitySelected = useDynamicIcon(markerCityLocationSelected, zoom)
  const { activeIcons, inactiveIcons } = useGetActiveAndInactiveIcons(zoom)
  const [clickedMarkerId, setClickedMarkerId] = useState(null)

  const filteredHeritages = filterHeritages(categoriesSelected, filters, heritages)
  const navigate = useNavigate()
  // Hook para capturar eventos de zoom y actualizar el estado
  const ZoomHandler = () => {
    useMapEvents({
      zoomend: () => {
        if (mapRef.current) {
          setZoom(mapRef.current.getZoom()) // Actualiza el estado del zoom
        }
      }
    })
    return null
  }

  const getAuthors = async () => {
    const list = await userService.getAllUsers()
    const options = list?.result?.map((user) => ({
      label: `${user.name} ${user.surname}`,
      value: user.id
    }))
    localStorage.setItem("users",JSON.stringify(options))
  }

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.eachLayer((layer) => {
        if (layer instanceof L.Marker) {
          layer.setIcon(layer.options.icon) // Forzar actualización de iconos
        }
      })
    }
    if (zoom < 9)
      !routeId && setHeritages([])
  }, [zoom, cityId ,heritageId]);
  
  useEffect(()=>{
    
    if(cities && cityId && !heritageId){
      const city = cities.find((item)=> item?.city_id == cityId)
      if(city){
        setClickedMarkerId(city.id);
        fetchHeritagesByCityId(city.city_id);
        setShowHeritages(true);
        mapRef.current.flyTo(
          [parseFloat(city.latitude), parseFloat(city.longitude)],
          mapRef.current?._zoom < 10 ? 15 : mapRef.current.getZoom(),
          {
            duration: 0.5
          }
        )
        openWindowAndSelect(city)
      }
    }
    if (cityId && heritageId) {
      heritageService.getAllHeritagesByCityId(cityId).then((data) => {
        const heritage = data?.find((item) => item && item?.id == heritageId)
        if (heritage) {
          fetchHeritagesByCityId(cityId);
          setShowHeritages(true);
          SetIsAdvanceSearch(false)
          mapRef.current.flyTo(
            [parseFloat(heritage.latitude), parseFloat(heritage.longitude)],
            mapRef.current?._zoom < 10 ? 15 : mapRef.current.getZoom(),
            {
              duration: 0.5
            }
          )
          openWindowAndSelect(heritage)
        }
      })
    }
  }, [cityId, heritageId, cities, useParams()])

  const handleSearch = (searchData) => {
    const { country, city, categories } = searchData

    heritageService
      .getAllHeritages(0, 0, {
        'country.name': country,
        'city.name': city
      })
      .then((res) => {
        const filteredHeritages = res.result.filter((heritage) => categories.includes(heritage.heritage_field_id))

        SetIsAdvanceSearch(true)
        setCities([])
        setHeritages(filteredHeritages)
        setFilters(searchData)
        setShowHeritages(true)
      })
  }

  useEffect(() => {
    !routeId && cityOcityService.getCities().then((data) => {
      setCities(data.result);
    });
    getAuthors();
  }, []);

  useEffect(() => {
    if (zoom < 11 && !isAdvanceSearch) {
      setClickedMarkerId(null)
    }
  }, [zoom])

  const fetchHeritagesByCityId = (id) => {
    heritageService.getAllHeritagesByCityId(id).then((data) => {
      const dataVerifies = data.filter(item => item.is_verified === true);
      SetIsAdvanceSearch(false)
      setHeritages(dataVerifies);
    });
  };

  // #FLAG_123: NEXT FEATURE TODO: ESTO HABILITA EL USO DE LAS SIGUIENTES FEATURES DE DISPONIBILIZAR RUTAS EN EL MAPA
 /*useEffect(()=>{
  routeId && roadService.getRoadRouteById(routeId).then((data) => {
      console.log("road data",data)
      setCities([]);
      setShowHeritages(true); 
      setHeritages(data);
      mapRef.current.flyTo(
        [parseFloat(data[0].latitude), parseFloat(data[0].longitude)],
        7, { duration: 0.5, }
      );
    })
 },[routeId])*/

  const openWindowAndSelect = (item) => {
    windowMarkerRef.current.setData(item)
    if (!windowMarkerRef.current.visible()) {
      windowMarkerRef.current.setVisible(true)
    }
    setClickedMarkerId(item.id)
  }

  const handleClickMarker = (item) => {
    // openWindowAndSelect(item)

    if (isCity(item)) {
      navigate(`/city/${item.city_id}`)
    }
    if (isHeritage(item)) {
      navigate(`/city/${item.city_id}/heritage/${item.id}`)
    }
  }

  //This function returns the changed
  const changes = (item) => {
    return item.id === clickedMarkerId ? iconCity : iconCitySelected
  }

  const heritageChanges = (item) => {
    const heritageId = item.heritage_field_id
    if (heritageId in activeIcons && heritageId in inactiveIcons) {
      return item.id === clickedMarkerId ? inactiveIcons[item.heritage_field_id] : activeIcons[item.heritage_field_id]
    } else {
      console.error(`Invalid heritage_field_id: ${heritageId}`)
      return inactiveIcons[1]
    }
  }

  const CitiesMarkers = useMemo(() => {
    return (
      <List
        width={100}
        height={100}
        rowCount={1}
        rowHeight={20}
        rowRenderer={() =>
          cities?.map((item, index) => (
            <Marker
              key={item.id}
              position={[item.latitude, item.longitude]}
              icon={changes(cities[index])}
              eventHandlers={{
                click: () => {
                  handleClickMarker(item)
                }
              }}
            >
              <Popup>
                <img src={item.image} />
                <h5 style={{ textAlign: 'center' }}>{item.city?.name}</h5>
              </Popup>
            </Marker>
          ))
        }
      />
    )
  }, [zoom, cities])

  const HeritageMarkers = useMemo(() => {
    return (
      <List
        width={100}
        height={100}
        rowCount={1}
        rowHeight={20}
        rowRenderer={() =>
          filteredHeritages.map((item) => (
            <Marker
              key={item.id}
              position={[item.latitude, item.longitude]}
              icon={heritageChanges(item)}
              eventHandlers={{
                click: () => {
                  handleClickMarker(item)
                }
              }}
            >
              <Popup>
                <img src={item.image} />
                <h5 style={{ textAlign: 'center' }}>{item.name}</h5>
              </Popup>
            </Marker>
          ))
        }
      />
    )
  }, [filteredHeritages, clickedMarkerId])


  const polylineStyle = {
    color: '#FF8C00',  // Color of the polyline
    weight: 5,        // Line thickness
    opacity: 0.7,     // Line opacity
    dashArray: '0.5, 25',
    lineCap: 'round' // Dash pattern (length of dashes and spaces)
  };

  const routeLine = useMemo(() => {
    if (heritages.length === 0) return null;
    
    const routeCoordinates = heritages.map(heritage => [
      parseFloat(heritage.latitude),
      parseFloat(heritage.longitude)
    ]);
    return <Polyline positions={routeCoordinates} pathOptions={polylineStyle} />;
  }, [heritages,routeId]);

  return (
    <div>
      <WindowMarker ref={windowMarkerRef} playContentsRef={playContentsRef} setClickedMarkerId={setClickedMarkerId} />
      <PlayContents ref={playContentsRef} windowMarkerRef={windowMarkerRef} />
      <MapMenu onUpdateCategories={setCategoriesSelected} onSearch={handleSearch} />

      <MapContainer
        className="markercluster-map"
        center={[51.0, 19.0]}
        zoom={zoom}
        ref={mapRef}
        // maxZoom={18}
        style={{ margin: '0px', height: '100vh', zIndex: 0 }}
        onzoomend={() => {
          const newZoom = mapRef.current.getZoom()
          setZoom(newZoom)
        }}
      >
        <ZoomHandler />
        <TileLayer
          url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Physical_Map/MapServer/tile/{z}/{y}/{x}"
          attribution="Tiles &copy; Esri &mdash; Source: Esri, DeLorme, NAVTEQ, USGS,
          Intermap, iPC, NRCAN, Esri Japan, METI, Esri China (Hong Kong), Esri
          (Thailand), TomTom, 2012"
          edgeBufferTiles={2}
        />
        <CustomEarthControl map={mapRef.current} defaultZoom={3} defaultCenter={[51.0, 19.0]} />
        {zoom >= 8 && (
          <TileLayer
            url="https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}"
            attribution="Tiles &copy; Esri &mdash; Source: US National Park Service"
          />
        )}
        {routeId && <LazyLoad once={true} threshold={200}>
          {routeLine}
        </LazyLoad>}
        {zoom >= 10 ? (
          <>
            <LazyLoad once={true} threshold={200}>  
              {CitiesMarkers}
            </LazyLoad>
          </>
        ) : (
          <MarkerClusterGroup
            chunkedLoading
            maxClusterRadius={80} // Ajusta el valor según tus necesidades
            spiderfyOnMaxZoom={false}
            showCoverageOnHover={true}
          >
            <LazyLoad once={true} threshold={200}>
              {CitiesMarkers}
            </LazyLoad>
          </MarkerClusterGroup>
        )}

        {showHeritages && (
          <LazyLoad once={true} threshold={200}>
            {HeritageMarkers}
          </LazyLoad>
        )}
      </MapContainer>
      <Footer />
    </div>
  )
}
